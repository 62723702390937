import React, { useState, useEffect } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css'; // Importa Materialize CSS


const Mis_recibos = ({ url }) => {

  useEffect(() => {
        
  }, []); // La dependencia vacía asegura que se ejecute solo una vez después del montaje inicial

  
  return (
    <div style={{ height: '100vh', width: '100vw', margin: 0 }}>
      <iframe
        src={url}
        style={{ height: '100%', width: '100%', border: 'none' }}
        title="Mis Recibos"
      />
    </div>
);
};

export default Mis_recibos;
