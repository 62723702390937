import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';


const Contactanos = () => {
  
    const [nombre, setNombre] = useState('');
    const [email, setEmail] = useState('');
    const [edificio, setEdificio] = useState('');
    const [nro_depas, setNro_depas] = useState('');
    const [distrito, setDistrito] = useState('');
    
    const handleSubmit = (e) => {
        e.preventDefault();

        // Define el número de WhatsApp y el mensaje
        var numeroWhatsApp = '51902656631';
        //var mensaje = 'Hola, soy ' + nombre + '. Mi correo es ' + email + ', deseo información para el edificio: ' +edificio+' de '+ nro_depas+' departamentos en el distrito de '+ distrito +'.';
        var mensaje = 'Mi correo es ' + email + ', deseo información para el edificio: ' +edificio+' de '+ nro_depas+' departamentos en el distrito de '+ distrito +'.';
        console.log("mensaje "+mensaje);
        // Construye el enlace de WhatsApp
        //var url = 'https://wa.me/' + numeroWhatsApp + '?text=' + encodeURIComponent(mensaje);

        const encodedMessage = encodeURIComponent(`Hola, soy: ${nombre}\nMensaje: ${mensaje}`);
        const whatsappLink = `https://wa.me/${numeroWhatsApp}?text=${encodedMessage}`;
        window.open(whatsappLink, '_blank');
        setNombre('');
        setEmail('');
        setEdificio('');
        setNro_depas('');
        setDistrito('');
      };

  return (
<div className="container">

<div className="container2" id="contacto">
    <h1>Contáctanos</h1>
     
    <form id="contactForm" onSubmit={handleSubmit}>
      Agrega aquí los campos de formulario que desees
      <div className="input-field">
        <input type="text" id="nombre" name="nombre" value={nombre} 
            onChange={(e) => setNombre(e.target.value)}
        required/>
        <label htmlFor="nombre">Nombre</label>
      </div>
      <div className="input-field">
        <input type="email" id="email" name="email" value={email} 
            onChange={(e) => setEmail(e.target.value)}
        required/>
        <label htmlFor="email">Correo Electrónico</label>
      </div>
      <div className="input-field">
        <input type="text" id="edificio" name="edificio" value={edificio} 
            onChange={(e) => setEdificio(e.target.value)}
        required/>
        <label htmlFor="edificio">Edificio</label>
      </div>
      <div className="input-field">
        <input type="text" id="nro_depas" name="nro_depas" value={nro_depas} 
            onChange={(e) => setNro_depas(e.target.value)}
        required/>
        <label htmlFor="nro_depas">número de depas en edificio</label>
      </div>
      <div className="input-field">
        <input type="text" id="distrito" name="distrito" value={distrito} 
            onChange={(e) => setDistrito(e.target.value)}
        required/>
        <label htmlFor="distrito">Distrito</label>
      </div>   
       
      
      <button className="btn waves-effect waves-light" type="submit" name="action">Enviar
        <i className="material-icons right">send</i>
      </button>
    </form>
     
  </div>

  
</div>
  );
};

export default Contactanos;
