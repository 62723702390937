import React, { useState, useEffect } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css'; // Importa Materialize CSS


const Home = () => {

  useEffect(() => {
    // Inicializa los tooltips después de que el componente se monte en el DOM
    M.Sidenav.init(document.querySelectorAll('.sidenav'));
    M.Collapsible.init(document.querySelectorAll('.collapsible'));
        
  }, []); // La dependencia vacía asegura que se ejecute solo una vez después del montaje inicial

  
  return (
<div className="container">
    <div className="container2">
        <h4 className="center-align">¡Moderniza tus Pagos de Mantenimiento de edificio y ahorra desde 60%!</h4>
        <div className="card horizontal">
          <div className="card-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/tantaq-apis.appspot.com/o/images%2Fcostaverde1.jpg?alt=media&token=c62fb7fc-bb7d-47b5-8468-0bc5d7dfa0b6"/>
          </div>
          <div className="card-stacked">
            <div className="card-content">
              <p>Tantaq es un servicio que facilita la cobranza de los recibos de mantenimiento de edificios residenciales.</p>
            </div>
            <div className="card-action">
              <a href="#">¿Que es Tantaq?</a>
            </div>
          </div>
        </div>
    
  </div>
  <div className="container2" id="beneficios">
    <h3 className="center-align" >Beneficios</h3>
        <div className="card horizontal">
          <div className="card-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/tantaq-apis.appspot.com/o/images%2Fcuentas_casa.jpg?alt=media&token=1ca197b7-4a57-46b7-8667-d10bc637ec47"/>
          </div>
          <div className="card-stacked">
            <div className="card-content">
              <p>Ahorro de 60% por comisiones que se pagan al banco en un servicio tradicional de recaudación</p>
            </div>
            <div className="card-action">
              <a href="#">Ahorra</a>
            </div>
          </div>
        </div>
  
        <div className="card horizontal">
          <div className="card-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/tantaq-apis.appspot.com/o/images%2Fpaga_yape_plin3.jpg?alt=media&token=c4c3c131-a9a4-4dc9-8a20-b43aee140b5e"/>
          </div>
          <div className="card-stacked">
            <div className="card-content">
              <p>Facilidad para pagar con Yape y Plin los recibos de mantenimiento</p>
            </div>
            <div className="card-action">
              <a href="#">Paga con facilidad</a>
            </div>
          </div>
        </div>
        
        <div className="card horizontal">
          <div className="card-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/tantaq-apis.appspot.com/o/images%2Fconciliacion_banco2.webp?alt=media&token=38bf0e11-62d2-4f5e-b99d-50513f590a3f"/>
          </div>
          <div className="card-stacked">
            <div className="card-content">
              <p>Confirmación de los pagos de los recibos de mantenimiento a la cuenta del Banco</p>
            </div>
            <div className="card-action">
              <a href="#">Confirmación con el Banco</a>
            </div>
          </div>
        </div>
  </div>
    
    <div className="card">
        <div className="card-image waves-effect waves-block waves-light">
          <img className="activator" src="https://firebasestorage.googleapis.com/v0/b/tantaq-apis.appspot.com/o/images%2Fperuvian_edificios.jpeg?alt=media&token=c2e6e70f-e396-45f9-befd-77a88cfa3e78"/>
        </div>
        <div className="card-content">
          <span className="card-title activator grey-text text-darken-4">Moderniza y ahorra<i className="material-icons right">more_vert</i></span>
          <p><a href="#">Descubre Tantaq</a></p>
        </div>
        <div className="card-reveal">
          <span className="card-title grey-text text-darken-4">Moderniza y ahorra<i className="material-icons right">close</i></span>
          <p>- Ahorro de 60% por comisiones que se pagan al banco en un servicio tradicional de recaudación</p>
          <p>- Facilidad para pagar con Yape y Plin los recibos de mantenimiento</p>
          <p>- Simplicidad para informar el pago de tu recibo, sólo envía la constancia de Pago y listo</p>
          <p>- Confirmación de los pagos de los recibos de mantenimiento a la cuenta del Banco</p>
        </div>
    </div>
  
    <div className="row">
      <div className="col s12">
        <h4 className="center-align">¡Moderniza tus Pagos de Mantenimiento de edificio y ahorra desde 60%!</h4>
        <p>Es un servicio que facilita la cobranza de los recibos de mantenimiento para edificios residenciales</p>
        <p className="center-align">Ahorro de 60% por comisiones que se pagan al banco en un servicio tradicional de recaudación</p>
        <p className="center-align">Facilidad para pagar con Yape y Plin los recibos de mantenimiento</p>
        <p className="center-align">Simplicidad para informar el pago de tu recibo, sólo envía la constancia de Pago y listo</p>
        <p className="center-align">Confirmación de los pagos de los recibos de mantenimiento a la cuenta del Banco</p>
      </div>
    </div>

<div className="container2" id="como-funciona">
  <h3>¿Cómo funciona el servicio?</h3>

  <ul className="collapsible" data-collapsible="accordion">

    <li>
      <div className="collapsible-header"><i className="material-icons">arrow_forward</i>Paso 1: Registro en Tantaq</div>
      <div className="collapsible-body">
        <p>Pídele al administrador de tu edificio que se registre en Tantaq.com a traves de este enlace</p>
        <p>El administrador de tu edificio registrará los usuarios de los departamentos quienes serán notificados vía email con el usuario y contraseña temporal de acceso a Tantaq.com</p>
      </div>
    </li>

    <li>
      <div className="collapsible-header"><i className="material-icons">arrow_forward</i>Paso 2: Emisión de Recibos</div>
      <div className="collapsible-body">
        <p>Tu administrador emitirá los recibos de mantenimiento del edificio a través de Tantaq.com por lo que te llegará un email con la información para el Pago</p>
        <p>El número a Yapear como la cuenta de Banco es consignada por el administrador de tu edificio en el momento del registro</p>
      </div>
    </li>

    <li>
      <div className="collapsible-header"><i className="material-icons">arrow_forward</i>Paso 3: Paga tu recibo con Yape o Plin</div>
      <div className="collapsible-body">
        <p>Paga por Yape o Plin al número indicado en el email y sube tu constancia de pago a Tantaq.com</p>
        <p>Sube la constancia de tu Pago a Tantaq.com y listo</p>        
      </div>
    </li>

    <li>
      <div className="collapsible-header"><i className="material-icons">arrow_forward</i>Paso 4: Confirmación del Banco</div>
      <div className="collapsible-body">
        <p>Te llegará un correo de confirmación del depósito de tu pago a la cuenta del Banco registrada por el administrador de tu edificio.</p>
        <p>El administrador dispondrá de reportes de control de Pago de los recibos de mantenimiento.</p>        
      </div>
    </li>

  </ul>
</div>
    
    <div className="container2" id="preguntas-y-respuestas">
        <h3 >Preguntas y Respuestas</h3>
        
              <ul className="collapsible" data-collapsible="accordion" id="accordion">        
                <li>
                  <div className="collapsible-header"><i className="material-icons">question_answer</i>¿Que servicio brindamos?</div>
                  <div className="collapsible-body">
                    <p>Brindamos una plataforma web para facilitar la cobranza de los recibos de mantenimiento de edificios residenciales</p>
                  </div>
                </li>
                        
                <li>
                  <div className="collapsible-header"><i className="material-icons">question_answer</i>¿A quienes va dirigido su servicio?</div>
                  <div className="collapsible-body">
                    <p>A los propietarios de departamentos en edificios residenciales quienes pagan sus recibos de mantenimiento </p>
                  </div>
                </li>
            
                <li>
                  <div className="collapsible-header"><i className="material-icons">question_answer</i>¿Porque debo usar su servicio?</div>
                  <div className="collapsible-body">
                    <p>Porque permitirá un ahorro de 60% con respecto al servicio de recaudación de los bancos, además permite la facilidad de pagar con Yape y Plin los recibos de mantenimiento a la vez que son confirmados por el Banco</p>
                    <p>Si tu edificio tiene 100 departamentos, significará un ahorro de hasta 5,000 soles anuales, que los puedes destinar para otros gastos de mantenimiento de tu edificio</p>
                  </div>
                </li>
                <li>
                  <div className="collapsible-header"><i className="material-icons">question_answer</i>¿Cuanto cuesta el servicio?</div>
                  <div className="collapsible-body">
                    <p>El servicio tiene una modalidad de suscripción mensual basado en la cantidad de usuarios equivalente a la cantidad de departamentos, contáctanos para alcanzarte un plan de suscripción de acuerdo a la cantidad de departamentos de tu edificio</p>
                  </div>
                </li>                
              </ul>
    </div>

    <div className="container2" id="ser-recaudador">
        <h4 className="center-align">¡Regístrate como Recaudador!</h4>
        <div className="card horizontal">
          <div className="card-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/tantaq-apis.appspot.com/o/images%2Fadmin_edificio05.jpg?alt=media&token=d4c1feb2-615e-4c56-b2aa-98a37e59191e"/>
          </div>
          <div className="card-stacked">
            <div className="card-content">
              <p>Si eres administrador de un edificio residencial, escríbenos a informes@tantaq.com para registrarte como recaudador en Tantaq y empieza a ahorrar ya¡</p>
            </div>
            <div className="card-action">
              <a href="#">Ser un recaudador</a>
            </div>
          </div>
        </div>

  </div>
 

  <div className="container2" id="contacto">
    <h1>Contáctanos</h1>
     
    <form id="contactForm">
      Agrega aquí los campos de formulario que desees
      <div className="input-field">
        <input type="text" id="nombre" name="nombre" required/>
        <label for="nombre">Nombre</label>
      </div>
      <div className="input-field">
        <input type="email" id="email" name="email" required/>
        <label for="email">Correo Electrónico</label>
      </div>
      <div className="input-field">
        <input type="text" id="edificio" name="edificio" required/>
        <label for="edificio">Edificio</label>
      </div>
      <div className="input-field">
        <input type="text" id="nro_depas" name="nro_depas" required/>
        <label for="nro_depas">número de depas en edificio</label>
      </div>
      <div className="input-field">
        <input type="text" id="distrito" name="distrito" required/>
        <label for="distrito">Distrito</label>
      </div>   
       
      
      <button className="btn waves-effect waves-light" type="submit" name="action">Enviar
        <i className="material-icons right">send</i>
      </button>
    </form>
     
  </div>
  
</div>
  );
};

export default Home;
