import React, { useState, useEffect } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css'; // Importa Materialize CSS


const Como_funciona = () => {

  useEffect(() => {
    // Inicializa los tooltips después de que el componente se monte en el DOM
    M.Sidenav.init(document.querySelectorAll('.sidenav'));
    M.Collapsible.init(document.querySelectorAll('.collapsible'));
        
  }, []); // La dependencia vacía asegura que se ejecute solo una vez después del montaje inicial



  return (
<div className="container">
<div className="container2" id="como-funciona">
  <h3>¿Cómo funciona el servicio?</h3>

  <ul className="collapsible" data-collapsible="accordion">

    <li>
      <div className="collapsible-header"><i className="material-icons">arrow_forward</i>Paso 1: Registro en Tantaq</div>
      <div className="collapsible-body">
        <p>Pídele al administrador de tu edificio que se registre en Tantaq.com a traves de este enlace</p>
        <p>El administrador de tu edificio registrará los usuarios de los departamentos quienes serán notificados vía email con el usuario y contraseña temporal de acceso a Tantaq.com</p>
      </div>
    </li>

    <li>
      <div className="collapsible-header"><i className="material-icons">arrow_forward</i>Paso 2: Emisión de Recibos</div>
      <div className="collapsible-body">
        <p>Tu administrador emitirá los recibos de mantenimiento del edificio a través de Tantaq.com por lo que te llegará un email con la información para el Pago</p>
        <p>El número a Yapear como la cuenta de Banco es consignada por el administrador de tu edificio en el momento del registro</p>
      </div>
    </li>

    <li>
      <div className="collapsible-header"><i className="material-icons">arrow_forward</i>Paso 3: Paga tu recibo con Yape o Plin</div>
      <div className="collapsible-body">
        <p>Paga por Yape o Plin al número indicado en el email y sube tu constancia de pago a Tantaq.com</p>
        <p>Sube la constancia de tu Pago a Tantaq.com y listo</p>        
      </div>
    </li>

    <li>
      <div className="collapsible-header"><i className="material-icons">arrow_forward</i>Paso 4: Confirmación del Banco</div>
      <div className="collapsible-body">
        <p>Te llegará un correo de confirmación del depósito de tu pago a la cuenta del Banco registrada por el administrador de tu edificio.</p>
        <p>El administrador dispondrá de reportes de control de Pago de los recibos de mantenimiento.</p>        
      </div>
    </li>

  </ul>
</div>
  
</div>
  );
};

export default Como_funciona;
