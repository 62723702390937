import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//npx create-react-app app-react
//npm start
//npm install react-router-dom
//npm install materialize-css@next
//npm install -g firebase-tools
//firebase init
//select "build" as directory public and SPA
//npm install firebase
//npm install axios
//npm install date-fns
//npm run build
//firebase deploy
