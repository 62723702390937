import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './features/home/homePage';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css'; // Importa Materialize CSS
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Beneficios from './features/home/beneficiosPage';
import Como_funciona from './features/home/como-funcionaPage';
import Contactanos from './features/home/contactanosPage';
import Preguntas from './features/home/preguntasPage';
import Ser_recaudador from './features/home/ser-recaudadorPage';
import Mis_recibos from './features/home/mis-recibosPage';
import MainLayout from './components/mainLayoutPage';


function mostrarMenus(clasesMenu) {
  //console.log("en mostrar menu "+clasesMenu);
  const claseMenuDefecto="menusitos";
  
  // Seleccionar todos los elementos div con la clase especificada
  var elementosLi_defecto = document.querySelectorAll('.'+claseMenuDefecto);

  // Iterar sobre la lista de elementos div y establecer display en 'none'
  elementosLi_defecto.forEach(function(li) {
    //console.log("before ocultando li.id "+li.id + " li.style.display "+li.style.display)
    li.style.display = 'none';
    //console.log("after ocultando li.id "+li.id + " li.style.display "+li.style.display)
    
  });

  //var elementosLi = document.querySelectorAll('.'+claseMenu);
// Suponiendo que tienes un array de clases llamado clasesMenu
//var clasesMenu = ['clase1', 'clase2', 'clase3'];

// Convertir clasesMenu en un array de strings si no lo es
const clasesArray = Array.isArray(clasesMenu) ? clasesMenu : [clasesMenu];
console.log("en mostrar menu clasesArray "+clasesArray);
// Creas un selector combinando todas las clases del array
var selector = clasesArray.map(clase => '.' + clase).join(',');

// Luego utilizas querySelectorAll con el selector combinado
var elementosLi = document.querySelectorAll(selector);
  
  // Iterar sobre la lista de elementos div y establecer display en 'none'
  elementosLi.forEach(function(li) {
    //console.log("before mostrando li.id "+li.id + " li.style.display "+li.style.display)
    li.style.display = 'block';
    //console.log("after mostrando li.id "+li.id + " li.style.display "+li.style.display)
    
  });
  
}

function App() {

  useEffect(() => {
    // Inicializa los tooltips después de que el componente se monte en el DOM
  }, []); // La dependencia vacía asegura que se ejecute solo una vez después del montaje inicial


  return (
    <Router>
        <Routes>          
          <Route path="/" exact element={
            <MainLayout showFooter={true}>
              <Home />
            </MainLayout>} />

          <Route path="/home" exact element={
            <MainLayout showFooter={true}>
              <Home />
            </MainLayout>} />
          
          <Route path="/beneficios" exact element={
            <MainLayout showFooter={true}>
              <Beneficios />
            </MainLayout>} />
          
          <Route path="/como-funciona" exact element={
            <MainLayout showFooter={true}>
              <Como_funciona />
            </MainLayout>} />

          <Route path="/contactanos" exact element={
            <MainLayout showFooter={true}>
              <Contactanos />
            </MainLayout>} />

          <Route path="/preguntas" exact element={
            <MainLayout showFooter={true}>
              <Preguntas />
            </MainLayout>} />

          <Route path="/ser-recaudador" exact element={
            <MainLayout showFooter={true}>
              <Ser_recaudador />
            </MainLayout>} />

          <Route path="/mis-recibos" exact element={<Mis_recibos url="https://script.google.com/macros/s/AKfycbxHalNoT_TWJ_p20u8WdCJcSpakwxosLc8yAKPyLqEfs-Ia9OBAXX2-_xkyz7QIvR1u_g/exec"/>} />
          
        </Routes>
    </Router>
  );
 
}

export default App;


//npx create-react-app app-home
//npm start
//npm install react-router-dom
//npm install materialize-css@next  //add files materialize in index.html
//npm install firebase
//firebase init
//npm install axios
//npm install redux react-redux @reduxjs/toolkit
//npm install redux-persist
//npm install redux-persist-expire
//npm install date-fns
//firebase deploy //to deploy in app hosting