import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';


const Ser_recaudador = () => {
  
  
  return (
<div className="container">

<div className="container2" id="ser-recaudador">
        <h4 className="center-align">¡Regístrate como Recaudador!</h4>
        <div className="card horizontal">
          <div className="card-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/tantaq-apis.appspot.com/o/images%2Fadmin_edificio05.jpg?alt=media&token=d4c1feb2-615e-4c56-b2aa-98a37e59191e"/>
          </div>
          <div className="card-stacked">
            <div className="card-content">
              <p>Si eres administrador de un edificio residencial, escríbenos a informes@tantaq.com para registrarte como recaudador en Tantaq y empieza a ahorrar ya¡</p>
            </div>
            <div className="card-action">
              <a href="#">Ser un recaudador</a>
            </div>
          </div>
        </div>

  </div>
  
</div>
  );
};

export default Ser_recaudador;
