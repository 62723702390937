import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';


const Beneficios = () => {
  
  
  return (
<div className="container">
  <div className="container2" id="beneficios">
    <h3 className="center-align" >Beneficios</h3>
        <div className="card horizontal">
          <div className="card-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/tantaq-apis.appspot.com/o/images%2Fcuentas_casa.jpg?alt=media&token=1ca197b7-4a57-46b7-8667-d10bc637ec47"/>
          </div>
          <div className="card-stacked">
            <div className="card-content">
              <p>Ahorro de 60% por comisiones que se pagan al banco en un servicio tradicional de recaudación</p>
            </div>
            <div className="card-action">
              <a href="#">Ahorra</a>
            </div>
          </div>
        </div>
  
        <div className="card horizontal">
          <div className="card-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/tantaq-apis.appspot.com/o/images%2Fpaga_yape_plin3.jpg?alt=media&token=c4c3c131-a9a4-4dc9-8a20-b43aee140b5e"/>
          </div>
          <div className="card-stacked">
            <div className="card-content">
              <p>Facilidad para pagar con Yape y Plin los recibos de mantenimiento</p>
            </div>
            <div className="card-action">
              <a href="#">Paga con facilidad</a>
            </div>
          </div>
        </div>
        
        <div className="card horizontal">
          <div className="card-image">
            <img src="https://firebasestorage.googleapis.com/v0/b/tantaq-apis.appspot.com/o/images%2Fconciliacion_banco2.webp?alt=media&token=38bf0e11-62d2-4f5e-b99d-50513f590a3f"/>
          </div>
          <div className="card-stacked">
            <div className="card-content">
              <p>Confirmación de los pagos de los recibos de mantenimiento a la cuenta del Banco</p>
            </div>
            <div className="card-action">
              <a href="#">Confirmación con el Banco</a>
            </div>
          </div>
        </div>
  </div>
    
    <div className="card">
        <div className="card-image waves-effect waves-block waves-light">
          <img className="activator" src="https://firebasestorage.googleapis.com/v0/b/tantaq-apis.appspot.com/o/images%2Fperuvian_edificios.jpeg?alt=media&token=c2e6e70f-e396-45f9-befd-77a88cfa3e78"/>
        </div>
        <div className="card-content">
          <span className="card-title activator grey-text text-darken-4">Moderniza y ahorra<i className="material-icons right">more_vert</i></span>
          <p><a href="#">Descubre Tantaq</a></p>
        </div>
        <div className="card-reveal">
          <span className="card-title grey-text text-darken-4">Moderniza y ahorra<i className="material-icons right">close</i></span>
          <p>- Ahorro de 60% por comisiones que se pagan al banco en un servicio tradicional de recaudación</p>
          <p>- Facilidad para pagar con Yape y Plin los recibos de mantenimiento</p>
          <p>- Simplicidad para informar el pago de tu recibo, sólo envía la constancia de Pago y listo</p>
          <p>- Confirmación de los pagos de los recibos de mantenimiento a la cuenta del Banco</p>
        </div>
    </div>
  
    <div className="row">
      <div className="col s12">
        <h4 className="center-align">¡Moderniza tus Pagos de Mantenimiento de edificio y ahorra desde 60%!</h4>
        <p>Es un servicio que facilita la cobranza de los recibos de mantenimiento para edificios residenciales</p>
        <p className="center-align">Ahorro de 60% por comisiones que se pagan al banco en un servicio tradicional de recaudación</p>
        <p className="center-align">Facilidad para pagar con Yape y Plin los recibos de mantenimiento</p>
        <p className="center-align">Simplicidad para informar el pago de tu recibo, sólo envía la constancia de Pago y listo</p>
        <p className="center-align">Confirmación de los pagos de los recibos de mantenimiento a la cuenta del Banco</p>
      </div>
    </div>

  
</div>
  );
};

export default Beneficios;
