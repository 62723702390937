import React, { useState, useEffect } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css'; // Importa Materialize CSS


const Preguntas = () => {

  useEffect(() => {
    // Inicializa los tooltips después de que el componente se monte en el DOM
    M.Sidenav.init(document.querySelectorAll('.sidenav'));
    M.Collapsible.init(document.querySelectorAll('.collapsible'));
        
  }, []); // La dependencia vacía asegura que se ejecute solo una vez después del montaje inicial

  
  return (
<div className="container">

<div className="container2" id="preguntas-y-respuestas">
        <h3 >Preguntas y Respuestas</h3>
        
              <ul className="collapsible" data-collapsible="accordion" id="accordion">        
                <li>
                  <div className="collapsible-header"><i className="material-icons">question_answer</i>¿Que servicio brindamos?</div>
                  <div className="collapsible-body">
                    <p>Brindamos una plataforma web para facilitar la cobranza de los recibos de mantenimiento de edificios residenciales</p>
                  </div>
                </li>
                        
                <li>
                  <div className="collapsible-header"><i className="material-icons">question_answer</i>¿A quienes va dirigido su servicio?</div>
                  <div className="collapsible-body">
                    <p>A los propietarios de departamentos en edificios residenciales quienes pagan sus recibos de mantenimiento </p>
                  </div>
                </li>
            
                <li>
                  <div className="collapsible-header"><i className="material-icons">question_answer</i>¿Porque debo usar su servicio?</div>
                  <div className="collapsible-body">
                    <p>Porque permitirá un ahorro de 60% con respecto al servicio de recaudación de los bancos, además permite la facilidad de pagar con Yape y Plin los recibos de mantenimiento a la vez que son confirmados por el Banco</p>
                    <p>Si tu edificio tiene 100 departamentos, significará un ahorro de hasta 5,000 soles anuales, que los puedes destinar para otros gastos de mantenimiento de tu edificio</p>
                  </div>
                </li>
                <li>
                  <div className="collapsible-header"><i className="material-icons">question_answer</i>¿Cuanto cuesta el servicio?</div>
                  <div className="collapsible-body">
                    <p>El servicio tiene una modalidad de suscripción mensual basado en la cantidad de usuarios equivalente a la cantidad de departamentos, contáctanos para alcanzarte un plan de suscripción de acuerdo a la cantidad de departamentos de tu edificio</p>
                  </div>
                </li>                
              </ul>
    </div>

  
</div>
  );
};

export default Preguntas;
