// src/components/MainLayout.js
import React from 'react';
import Menu from './menuPage';
import Footer from './footerPage';

const MainLayout = ({ children, showFooter }) => {
  return (
    <div>
      <Menu />
      <div>{children}</div>
      {showFooter && <Footer />}
    </div>
  );
};

export default MainLayout;
