import { Link } from 'react-router-dom';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css'; // Importa Materialize CSS
import React, { useEffect } from 'react';

function mostrarMenus(clasesMenu) {
    //console.log("en mostrar menu "+clasesMenu);
    const claseMenuDefecto="menusitos";
    
    // Seleccionar todos los elementos div con la clase especificada
    var elementosLi_defecto = document.querySelectorAll('.'+claseMenuDefecto);
  
    // Iterar sobre la lista de elementos div y establecer display en 'none'
    elementosLi_defecto.forEach(function(li) {
      //console.log("before ocultando li.id "+li.id + " li.style.display "+li.style.display)
      li.style.display = 'none';
      //console.log("after ocultando li.id "+li.id + " li.style.display "+li.style.display)
      
    });
  
    //var elementosLi = document.querySelectorAll('.'+claseMenu);
  // Suponiendo que tienes un array de clases llamado clasesMenu
  //var clasesMenu = ['clase1', 'clase2', 'clase3'];
  
  // Convertir clasesMenu en un array de strings si no lo es
  const clasesArray = Array.isArray(clasesMenu) ? clasesMenu : [clasesMenu];
  console.log("en mostrar menu clasesArray "+clasesArray);
  // Creas un selector combinando todas las clases del array
  var selector = clasesArray.map(clase => '.' + clase).join(',');
  
  // Luego utilizas querySelectorAll con el selector combinado
  var elementosLi = document.querySelectorAll(selector);
    
    // Iterar sobre la lista de elementos div y establecer display en 'none'
    elementosLi.forEach(function(li) {
      //console.log("before mostrando li.id "+li.id + " li.style.display "+li.style.display)
      li.style.display = 'block';
      //console.log("after mostrando li.id "+li.id + " li.style.display "+li.style.display)
      
    });
    
  }

const Menu = () => {

    useEffect(() => {
        // Inicializa los tooltips después de que el componente se monte en el DOM
        M.Tooltip.init(document.querySelectorAll('.tooltipped'));
        M.Dropdown.init(document.querySelectorAll('.dropdown-trigger'));
        M.Sidenav.init(document.querySelectorAll('.sidenav'));
        mostrarMenus("menu_inicio");
        console.log("LUEGO DE MOSTRAR MENU INICIO");
      }, []); // La dependencia vacía asegura que se ejecute solo una vez después del montaje inicial
    
      const openEmbeddedPage = () => {
        window.open('/mis-recibos', '_blank');
      };

      
  return (
    <div id="menu">
    <nav>
      <div className="nav-wrapper  blue darken-1">            
        <Link to="/" className="brand-logo hide-on-med-and-down text-large" id="tituloPagina">
          <i className="material-icons">cloud</i>Tantaq              
        </Link>
        <Link to="/" className="brand-logo hide-on-large-only" id="tituloPagina_short">
          <i className="material-icons">cloud</i>
          <span className="tituloSmallDevices">Tantaq</span>              
        </Link>
        <Link to="#" data-target="mobile-demo" className="sidenav-trigger">
          <i className="material-icons">menu</i>
        </Link>
        <ul className="right hide-on-med-and-down">              
          <li id="menu_beneficios" className="menusitos menu_inicio">
            <Link to="/beneficios">
              Beneficios
            </Link>
          </li>
          <li id="menu_como_funciona" className="menusitos menu_inicio">
            <Link to="/como-funciona">
              ¿Como funciona?
            </Link>
          </li>
          <li id="menu_preguntas" className="menusitos menu_inicio">
            <Link to="/preguntas">
              Preguntas
            </Link>
          </li>
          <li id="menu_login" className="menusitos menu_inicio">
              <a href="#!" onClick={openEmbeddedPage}>Login</a>
          </li>
          <li id="menu_ser_recaudador" className="menusitos menu_inicio">
            <Link to="/ser-recaudador">
              Ser Recaudador
            </Link>
          </li>
          <li id="menu_contactanos" className="menusitos menu_inicio">
            <Link to="/contactanos">
              Contáctanos
            </Link>
          </li>

        </ul>                
      </div>
    </nav>
    <ul className="sidenav" id="mobile-demo">
      <li id="menu_lat_beneficios" className="menusitos menu_inicio">
        <Link to="/beneficios">
              Beneficios
        </Link>               
      </li>
      <li id="menu_lat_como_funciona" className="menusitos menu_inicio">
        <Link to="/como-funciona">
              ¿Como funciona?
        </Link>               
      </li>
      <li id="menu_lat_preguntas" className="menusitos menu_inicio">
        <Link to="/preguntas" >
              Preguntas
        </Link>               
      </li>      
      <li id="menu_lat_login" className="menusitos menu_inicio">
            <a href="#!" onClick={openEmbeddedPage}>Login</a>
      </li>      
      <li id="menu_lat_ser_recaudador" className="menusitos menu_inicio">
        <Link to="/ser-recaudador" >
              Ser Recaudador
        </Link>
      </li>
      <li id="menu_lat_contactanos" className="menusitos menu_inicio">
        <Link to="/contactanos" >
          Contáctanos
        </Link>
      </li>                                  
    </ul>
    </div>
  );
};

export default Menu;
